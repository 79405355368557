import clsx from "clsx";
import "./styles.scss";

export enum TypeStatusTag {
	ACTIVE = "active",
	INACTIVE = "inactive",
	INCOMPLETE = "incomplete",
	PENDING = "pending"
}

type StatusTagProps = {
	text: string;
	type: TypeStatusTag;
	isBenefit?: boolean;
};

export default function StatusTag({
	text,
	type,
	isBenefit = false
}: StatusTagProps) {
	return (
		<span
			className={clsx(`status-tag ${type}`, {
				benefit: isBenefit
			})}
		>
			{text}
		</span>
	);
}
