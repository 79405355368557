import { lazy } from "react";
import Button from "./Button";
import Container from "./Container";
import DownloadMenu from "./DownloadMenu";
import FixedButton from "./FixedButton";
import GoogleAnalytics4 from "./GoogleAnalytics4";
import Header from "./Header";
import Input from "./Input";
import Label from "./Label";
import Loading from "./Loading";
import OTPInput from "./OTPInput";
import SearchBar from "./SearchBar";
import Section from "./Section";

const WrapperAccordion = lazy(
	() => import(/* webpackChunkName: "LandingPageComponents" */ "./Accordion")
);
const Avatar = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Avatar")
);
const BannerAppDownload = lazy(
	() =>
		import(
			/* webpackChunkName: "LandingPageComponents" */ "./BannerAppDownload"
		)
);
const BaseModal = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./BaseModal")
);
const Benefit = lazy(
	() => import(/* webpackChunkName: "LandingPageComponents" */ "./Benefit")
);
const BenefitCardPartnerSection = lazy(
	() =>
		import(
			/* webpackChunkName: "PartnerSectionComponents" */ "./BenefitCardPartnerSection"
		)
);
const BenefitsCarousel = lazy(
	() =>
		import(/* webpackChunkName: "LandingPageComponents" */ "./BenefitsCarousel")
);
const BenefitForms = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./BenefitForms")
);
const ClickableCard = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./ClickableCard")
);
const DetailsCard = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./DetailsCard")
);
const DetailsCardMobile = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./DetailsCardMobile")
);
const DetailsMainPanel = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./DetailsMainPanel")
);
const DynamicSelectInputGroup = lazy(
	() =>
		import(
			/* webpackChunkName: "BackofficePages" */ "./DynamicSelectInputGroup"
		)
);
const DynamicSelectTextInputGroup = lazy(
	() =>
		import(
			/* webpackChunkName: "BackofficePages" */ "./DynamicSelectTextInputGroup"
		)
);
const HeaderBackoffice = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./HeaderBackoffice")
);
const HeaderInfo = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./HeaderInfo")
);
const InfiniteScroll = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./InfiniteScroll")
);
const ListItem = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./List/ListItem")
);
const LocationsCarousel = lazy(
	() =>
		import(
			/* webpackChunkName: "LandingPageComponents" */ "./LocationsCarousel"
		)
);
const Modal = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Modal")
);
const ModalRedirect = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./ModalRedirect")
);
const Pagination = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Pagination")
);
const PartnerAdvantage = lazy(
	() =>
		import(/* webpackChunkName: "LandingPageComponents" */ "./PartnerAdvantage")
);
const PartnerForms = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./PartnerForms")
);
const Profile = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Profile")
);
const SelectForm = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./SelectForm")
);
const SortFilter = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./SortFilter")
);
const StatusTag = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./StatusTag")
);
const Stepper = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Stepper")
);
const StepsCarousel = lazy(
	() =>
		import(/* webpackChunkName: "LandingPageComponents" */ "./StepsCarousel")
);
const Table = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Table")
);
const TextArea = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./TextArea")
);
const TooltipComponent = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./Tooltip")
);

export {
	Avatar,
	BannerAppDownload,
	BaseModal,
	Benefit,
	BenefitCardPartnerSection,
	BenefitForms,
	BenefitsCarousel,
	Button,
	ClickableCard,
	Container,
	DetailsCard,
	DetailsCardMobile,
	DetailsMainPanel,
	DownloadMenu,
	DynamicSelectInputGroup,
	DynamicSelectTextInputGroup,
	FixedButton,
	GoogleAnalytics4,
	Header,
	HeaderBackoffice,
	HeaderInfo,
	InfiniteScroll,
	Input,
	Label,
	ListItem,
	Loading,
	LocationsCarousel,
	Modal,
	ModalRedirect,
	OTPInput,
	Pagination,
	PartnerAdvantage,
	PartnerForms,
	Profile,
	SearchBar,
	Section,
	SelectForm,
	SortFilter,
	StatusTag,
	Stepper,
	StepsCarousel,
	Table,
	TextArea,
	TooltipComponent,
	WrapperAccordion
};
