import colors from "colors";
import { IconProps } from "./types";

export default function IcoFilter({
	color = colors.neutral["low-pure-500"],
	viewBox = "0 0 24 25",
	size = "25"
}: Readonly<IconProps>) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.5 2H2.5L7 8V12.5L10 14V8L14.5 2Z"
				stroke={color}
				strokeLinejoin="round"
			/>
		</svg>
	);
}
