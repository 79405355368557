import colors from "colors";
import { IconProps } from "./types";

type IcoStarProps = IconProps & {
	filled?: boolean;
};

export default function IcoStar({
	color = colors.neutral["low-pure-500"],
	size = "24",
	viewBox = "0 0 24 24",
	filled = false
}: IcoStarProps) {
	return filled ? (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.4374 1.75772C12.3493 1.59868 12.1818 1.5 12 1.5C11.8182 1.5 11.6507 1.59868 11.5626 1.75772L8.27179 7.6985L1.889 9.1518C1.71858 9.1906 1.58079 9.31559 1.52559 9.48143C1.4704 9.64728 1.50581 9.8299 1.619 9.9631L5.98204 15.0974L5.32197 21.9521C5.30479 22.1304 5.38438 22.3044 5.5306 22.408C5.67681 22.5116 5.86728 22.529 6.02987 22.4537L12 19.6874L17.9701 22.4537C18.1327 22.529 18.3232 22.5116 18.4694 22.408C18.6156 22.3044 18.6952 22.1304 18.678 21.9521L18.018 15.0974L22.381 9.9631C22.4942 9.8299 22.5296 9.64728 22.4744 9.48143C22.4192 9.31559 22.2814 9.1906 22.111 9.1518L15.7282 7.6985L12.4374 1.75772Z"
				fill={color}
			/>
		</svg>
	) : (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 1.5C12.1818 1.5 12.3493 1.59868 12.4374 1.75772L15.7282 7.6985L22.111 9.1518C22.2814 9.1906 22.4192 9.31559 22.4744 9.48143C22.5296 9.64728 22.4942 9.8299 22.381 9.9631L18.018 15.0974L18.678 21.9521C18.6952 22.1304 18.6156 22.3044 18.4694 22.408C18.3232 22.5116 18.1327 22.529 17.9701 22.4537L12 19.6874L6.02987 22.4537C5.86728 22.529 5.67681 22.5116 5.5306 22.408C5.38438 22.3044 5.30479 22.1304 5.32197 21.9521L5.98204 15.0974L1.619 9.9631C1.50581 9.8299 1.4704 9.64728 1.52559 9.48143C1.58079 9.31559 1.71858 9.1906 1.889 9.1518L8.27179 7.6985L11.5626 1.75772C11.6507 1.59868 11.8182 1.5 12 1.5ZM12 3.03186L9.0382 8.37866C8.96925 8.50312 8.85055 8.59231 8.71182 8.6239L2.91491 9.9438L6.88102 14.611C6.96857 14.714 7.01066 14.8481 6.9977 14.9827L6.40098 21.1796L11.7898 18.6827C11.9231 18.6209 12.0769 18.6209 12.2102 18.6827L17.599 21.1796L17.0023 14.9827C16.9894 14.8481 17.0314 14.714 17.119 14.611L21.0851 9.9438L15.2882 8.6239C15.1495 8.59231 15.0308 8.50312 14.9618 8.37866L12 3.03186Z"
				fill={color}
			/>
		</svg>
	);
}
